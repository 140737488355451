<template>
  <div class="login-container">
    <img src="../assets/icon/share/logo.png" />
    <p class="app-name">Meta彼岸</p>
    <van-form
      validate-first
      @submit="login"
    >
      <div class="login-phone">
        <van-field
          autocomplete="off"
          v-model="phone"
          maxlength="11"
          ref="phone"
          type="tel"
          name="validatorPhone"
          placeholder="请输入您的手机号码"
          :rules="[{ validator:validatorPhone, message: '请输入正确的手机号码' }]"
        />
        <p class="login-phone-tip">+86</p>
      </div>
      <div
        v-if="isCaptcha"
        @click="getSms"
        class="right-slider-container"
      >
        <p class="right-slider-p">滑块验证</p>
        <div
          id="login-main-right-slider"
          class="login-main-right-slider"
        >
          <!-- 滑块验证 -->
        </div>
      </div>

      <div class="sms-container">
        <van-field
          autocomplete="off"
          v-model="sms"
          ref="sms"
          class="login-sms"
          maxlength="6"
          type="digit"
          name="validatorSms"
          placeholder="请输入短信验证码"
          :rules="[{validator:validatorSms, message: '请输入正确的短信验证码' }]"
        />
        <button
          type="button"
          class="sms-button"
          :disabled="disabled"
          @click="getSms"
        >
          {{ text }}
        </button>
      </div>
      <!-- 
      <div style="margin: 16px;"> -->
      <van-button
        round
        block
        class="login-button row-center"
        type="info"
        native-type="submit"
      >注册</van-button>
      <!-- </div> -->
    </van-form>
    <!-- <div class="slider-mask">

    </div> -->
    <div class="user-agreement">
      <p class="user-agreement-title">注册Meta彼岸即表明同意</p>
      <p class="user-agreement-tip"><a @click="goAgreementPolicy('agreement')">《用户协议》</a> | <a @click="goAgreementPolicy('policy')">《隐私政策》</a></p>
    </div>
    <!-- <div
      class="explore"
      v-if="show"
    >
      <div class="tops">
        <p>点击右上角按钮，然后在弹出的菜单中，点击在浏览器中打开，注册。</p>
        <img
          src="../assets/icon/share.png"
          alt=""
        >
      </div>
    </div> -->
  </div>
</template>
</template>
<script>
import { create } from '../lib/captcha.js'
import { captchaAppKey } from '../lib/index.js'
import api from '../api/index-client'
import Cookies from 'js-cookie'
import md5 from 'js-md5';
const config = require('../config')
import Vue from 'vue';
import { Form,Field,Button,Dialog } from 'vant';

Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Dialog);

export default {
  data () {
    return {
      isCaptcha: false,
      show: false,
      time: null,
      phone: '',
      value2: '',
      sms: '',
      disabled: false,
      text: '获取验证码',
      loginParam: {
        sessionId: '',
        sign: '',
        token: '',
        scene: '',
        username: '',
        password: ''
      }
    }
  },
  watch: {
    phone () {
      if (this.phone.length >= 11 || !/^[0-9]*$/.test(this.phone)) {
        this.$refs.phone.validate()
      }
    },
    sms () {
      if (this.sms.length >= 6 || !/^[0-9]*$/.test(this.sms)) {
        this.$refs.sms.validate()
      }
    }
  },
  created () {
    this.$store.commit("HIDE_APPLOADING")
    if (this.isWechat()) {
      this.show = true
    }
  },
  methods: {
    isWechat () {
      return (
        /micromessenger/i.test(navigator.userAgent) ||
        typeof WeixinJSBridge !== "undefined"
      );
    },
    validatorPhone (val) {
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val);
    },
    validatorSms (val) {
      return /^\d{6}$/.test(val);
    },
    login () {
      // if (this.isWechat()) {
      //   Dialog.alert({
      //     confirmButtonColor: '#7C75FF',
      //     message: '点击右上角按钮，然后在弹出的菜单中，点击在浏览器打开，登录',
      //   }).then(() => {
      //     // on close
      //   });
      // } else {
      api
        .post('mlogin',{ "mobile": this.phone,"code": this.sms,"deviceNo": "xxxxxxx","from": "h5" })
        .then(result => {
          if (result.data.success) {
            Cookies.set('Login',result.data.data.token,{ expires: 7 })
            this.$router.push('/download')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
      // }
    },
    goAgreementPolicy (e) {
      this.$router.push('/agreementPolicy?type=' + e)
    },
    getSms () {
      // if (this.isWechat()) {

      //   Dialog.alert({
      //     confirmButtonColor: '#7C75FF',
      //     message: '点击右上角按钮，然后在弹出的菜单中，点击在浏览器打开，即可安装',
      //   }).then(() => {
      //     // on close
      //   });
      // } else {
      /**
      * 创建滑块验证模块，并重置登录所需参数
      */
      this.$refs.phone.validate().then(result => {
        if (!result) {
          this.isCaptcha = true
          this.$nextTick(() => {
            this.captcha = create('login-main-right-slider',data => {
              var timestamp = new Date().getTime()
              var nonce = md5(String(timestamp))
              var signStr = 'appId=' + config.appId + '&mobile=' + this.phone + '&nonce=' + nonce + '&timestamp=' + timestamp + '&url=' + config.server + '/api/sms&' + config.appSecret
              var sign = md5(signStr)
              api
                .post('afs/sms',{ phone: this.phone,sessionId: data.sessionId,signature: data.sign,token: data.token,scene: data.scene,appkey: captchaAppKey },{},{ 'X-Requested-With': 'XMLHttpRequest','Access-Control-Allow-Origin': '*','Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization': "Bearer " + Cookies.get('Login'),"appId": config.appId,"timestamp": timestamp,"nonce": nonce,"sign": sign,"url": config.server + '/api/sms' })
                .then(result => {
                  if (result.data.success) {
                    this.time = 60;
                    this.timer();
                    this.$toast.success('验证码发送成功')
                  } else {
                    this.$toast({
                      message: result.data.msg,
                      icon: require('../assets/icon/toast-error.png'),
                    });
                  }
                  this.isCaptcha = false
                })
            })
            this.captcha.reset()
            this.captcha.show()
          })

        }
      })
      // }
    },
    //发送手机验证码倒计时
    timer () {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.text = this.time + "秒";
        setTimeout(this.timer,1000);
      } else {
        this.time = 0;
        this.text = "发送验证码";
        this.disabled = false;
      }
    },
  },
}
</script>